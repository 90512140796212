<template>
  <div class="c-product">
    <div class="header">
      <div class="status" :class="[$n.productStatus(item.status).color]">
        {{ $n.productStatus(item.status).title }}
      </div>
      <div class="date">{{ dateTitle }}: {{ $app.date.format(item.createdAt) }}</div>
    </div>
    <div class="body">
      <div class="left">
        <div class="order-image">
          <div v-for="image in item.images" :key="image.id" :class="['image', {small: item.images.length > 1}]">
            <div class="image-offset">
              <img :src="image.src" alt="image">
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="info">
          <div class="title">{{ item.title }}</div>
          <div v-if="item.code" class="item"><span>Артикул:</span> {{ item.code }}</div>
          <div v-if="deliver" class="item"><span>Способ отправки:</span> {{ deliver }}</div>
        </div>
        <div class="price-content">
          <div class="count">{{ item.count }} шт</div>
          <div class="price">{{ $n.numberFormat(item.cost) }} ₸</div>
        </div>
        <div class="tools">
          <slot name="tools" :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CProduct',
  props: {
    item: { type: Object, required: true, },
    dateTitle: { type: String, default: 'Дата создания', },
  },
  computed: {
    deliver() {
      const result = []
      if (this.item.canPickup) result.push('Самовывоз')
      if (this.item.canDeliver) result.push('Доставка')
      return result.join(' или ')
    },
  },
}
</script>

<style lang="scss" scoped>
.c-product {
  margin-bottom: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .status {
      font-size: 12px;

      &.success { color: #169401; }

      &.danger { color: #FF5139; }
    }

    .date {
      font-size: 12px;
      color: #404040;
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    background: #FFF;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    padding: 15px;

    .left {
      padding-right: 7px;
      width: 40%;

      .order-image {
        display: flex;
        flex-wrap: wrap;
        height: 115px;
        overflow: hidden;
      }

      .image {
        width: 115px;
        height: 115px;
        text-align: center;

        .image-offset {
          padding: 3px;
          height: 100%;
          width: 100%;
        }

        &.small {
          width: 57px;
          height: 57px;

          .plug { display: none; }
          &:nth-child(4) {
            .plug { display: block; }
            img { display: none; }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .right {
      padding-left: 7px;
      width: 60%;
    }

    .title {
      margin-bottom: 6px !important;
      font-weight: 500;
      font-size: 18px;
      color: #000;
    }

    .info {
      border-bottom: 1px solid #F2F2F2;
      padding-bottom: 5px;
      margin-bottom: 5px;
      font-size: 12px;
      color: #404040;

      & > div {
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
        span {
          margin-right: 3px;
          color: #BABABA;
        }
      }
    }

    .price-content {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .count {
      }
      .price {
        font-weight: bold;
        font-size: 14px;
        color: #404040;
      }
    }
    .tools {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &::v-deep {
    .n-button{
      font-size: 9px;
      border-radius: 10px;
      border: solid 1px #F2F2F2;
    }
  }
}
</style>
