<template>
  <div :class="[ 'n-slider', { pagination, } ]">
    <n-loader :loading="loading" />
    <swiper :options="s_options">
      <swiper-slide v-for="(item, i) in data" :key="i">
        <slot name="item" :item="item"></slot>
      </swiper-slide>

      <div v-if="scrollbar" :id="'swiper-scrollbar-'+name" slot="scrollbar" class="swiper-scrollbar"></div>
      <div v-if="pagination" :id="'swiper-pagination-'+name" slot="pagination" class="swiper-pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'NSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: { type: Array, default: () => [], },
    loading: { type: Boolean, default: false, },
    scrollbar: { type: Boolean, default: false, },
    pagination: { type: Boolean, default: false, },
    // https://swiperjs.com/swiper-api#parameters
    options: { type: Object, default: () => ({}), },
  },
  data() {
    return {
      name: (Math.random() + '').substr(2, 5),
    }
  },
  computed: {
    s_options() {
      const result = {
        ...this.options,
      }
      if (this.scrollbar) {
        result.scrollbar = {
          el: '#swiper-scrollbar-' + this.name,
          draggable: true,
        }
      }
      if (this.pagination) {
        result.pagination = {
          el: '#swiper-pagination-' + this.name,
        }
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.n-slider {
  position: relative;

  &::v-deep {
    .swiper-scrollbar {
      background: transparent;
    }

    .swiper-scrollbar-drag {
      background: #4BB222;
    }

    .swiper-container-horizontal > .swiper-scrollbar {
      position: relative;
      margin-top: 16px;
      left: 0;
    }

    .swiper-pagination {
      bottom: 0;

      .swiper-pagination-bullet {
        background: var(--secondary);
      }
    }

    &.pagination {
      .swiper-wrapper {
        padding-bottom: 24px;
      }
    }

    .swiper-slide {
      width: 100%;
    }
  }
}
</style>
