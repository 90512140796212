import Vue from 'vue'
import NastCore from 'nast'
import settings from './_config'
import libs from './_libs'
import ui from './_ui'
import components from 'components'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import { defineCustomElements } from '@ionic/pwa-elements/loader';

Vue.use(VueToast)
Vue.use(defineCustomElements)
Vue.mixin({
  components,
})
Vue.use(ui)
NastCore.createApp(settings, libs)

