<template>
  <div class="sort">
    <div class="search-wrap">
      <n-icon icon="search" />
      <input v-model="search" type="text" placeholder="Поиск" @change="filter">
    </div>
    <div class="wrap">
      <div v-show="isGeo" class="switch" :class="!geoSort ? '' : 'active'" @click="geoSort = !geoSort">
        <n-icon icon="balloon" />
      </div>

      <div v-show="isSelect" style="width: 100%">
        <n-select :data="sorts" :value.sync="sort" @select="filter" />
      </div>

      <div v-if="isList" class="view-switch">
        <n-icon icon="tile" :class="isActive ? '' : 'active'" @click="$emit('update:isActive', false)" />
        <n-icon icon="list" :class="!isActive ? '' : 'active'" @click="$emit('update:isActive', true)" />
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="geoSort" class="geo-sort">
        <VueSlideBar v-model="value" :data="slider.data" :range="slider.range" />
      </div>
    </transition>
  </div>
</template>

<script>
import VueSlideBar from 'vue-slide-bar'

export default {
  name: 'Sort',
  components: {
    VueSlideBar,
  },
  props: {
    isActive: { type: Boolean, default: false, },
    isGeo: { type: Boolean, default: false, },
    isSelect: { type: Boolean, default: true, },
    isList: { type: Boolean, default: true, },
    model: { type: String, default: 'product', },
  },
  data() {
    return {
      geoSort: false,
      search: '',
      value: 10,
      sort: { title: 'Сначала новые', value: 'new', api: (api) => api.sort('createdAt', 'desc'), },
      slider: {
        data: [
          15, 30, 45, 60, 75, 90, 120,
        ],
        range: [
          {
            label: '15 mins',
          },
          {
            label: '30 mins',
            // isHide: true,
          },
          {
            label: '45 mins',
          },
          {
            label: '1 hr',
            // isHide: true,
          },
          {
            label: '1 hr 15 mins',
          },
          {
            label: '1 hr 30 mins',
            // isHide: true,
          },
          {
            label: '2 hrs',
          },
        ],
      },
    }
  },
  computed: {
    sorts() {
      return this.model === 'product' ? [
        { title: 'Сначала новые', value: 'new', api: (api) => api.sort('createdAt,desc'), },
        { title: 'Сначала старые', value: 2, api: (api) => api.sort('createdAt,asc'), },
        { title: 'Сначала дешевые', value: 6, api: (api) => api.sort('cost,asc'), },
        { title: 'Сначала дорогие', value: 7, api: (api) => api.sort('cost,desc'), },
        { title: 'Сначала популярные', value: 3, api: (api) => api.sort('salesCount,desc'), },
        { title: 'По алфавиту (А-Я)', value: 4, api: (api) => api.sort('title,asc'), },
        { title: 'По алфавиту (Я-А)', value: 5, api: (api) => api.sort('title,desc'), },
      ] : [
        { title: 'Сначала новые', value: 'new', api: (api) => api.sort('createdAt,desc'), },
        { title: 'Сначала старые', value: 2, api: (api) => api.sort('createdAt,asc'), },
        { title: 'По рейтингу', value: 6, api: (api) => api.sort('rating,desc'), },
        { title: 'По алфавиту (А-Я)', value: 4, api: (api) => api.sort('title,asc'), },
        { title: 'По алфавиту (Я-А)', value: 5, api: (api) => api.sort('title,desc'), },
      ]
    },
  },
  mounted() {
    this.filter()
  },
  methods: {
    filter() {
      this.$emit('filter', (api) => {
        if (this.search) {
          api.filter({ title: 'search:'+this.search, })
        }
        if (this.sort) {
          this.sort.api(api)
        }
        return api
      })
    },
  },
}
</script>

<style scoped lang="scss">
.sort {
  margin: 10px 0 24px;
  .search-wrap{
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    height: 40px;
    padding: 0 20px;
    border-radius: 10px;
    margin-bottom: 10px;

    input{
      width: 100%;
      border: none;
      margin-left: 5px;
      &:focus{
        outline: none;
      }
    }
  }
  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .view-switch {
    display: flex;
    margin-left: 10px;

    &::v-deep {
      svg {
        &:not(:last-child) {
          margin-right: 10px;
        }

        &.active {
          g {
            fill: #4BB222 !important;
            transition: fill .2s ease;
          }
        }
      }
    }
  }


  .geo-sort {
    padding: 10px;
    background: #FFF;
    box-shadow: 4px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 15px;
  }

  .switch {
    transition: background-color .2s ease;
    border-radius: 10px;
    height: 39px;
    width: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F3F3F3;
    path{
     transition: path .2s ease;
    }

    &.active{
      background-color: #4BB222;

      &::v-deep path{
        fill: #fff;
      }
    }
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .1s ease;
    height: 0;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translate(0, -10px);
    opacity: 0;
  }
}
</style>

<!--для ползунка поиска по расстоянию-->
<style>
.vue-slide-bar {
  height: 10px !important;
  border-radius: 5px !important;
  background: #F5F5F5 !important;
}

.vue-slide-bar-process {
  background: #4BB222 !important;
}

.vue-slide-bar-separate-text {
  font-size: 9px;
  color: #ADADAD;
}
</style>
