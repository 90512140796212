<template>
  <div class="top-bar">
    <div v-if="sidebar" class="menu-wrap">
      <div class="menu">
        <div class="content">
          <ul v-if="!isStore">
            <li v-for="(item, inx) in userMenu" :key="inx">
              <n-link :to="{name: item.link,}">
                <div class="icon">
                  <n-icon :icon="item.icon" />
                </div>
                <div class="title">{{ item.title }}</div>
                <div v-if="item.count && salesCount" class="count">{{ salesCount }}</div>
              </n-link>
            </li>
          </ul>

          <ul v-else>
            <li v-for="(item, inx) in shopMenu" :key="inx">
              <n-link :to="{name: item.link,}">
                <div class="icon">
                  <n-icon :icon="item.icon" />
                </div>
                <div class="title">{{ item.title }}</div>
                <div v-if="item.count" class="count">4</div>
              </n-link>
            </li>
          </ul>

          <div class="logo">
            <img :src="logo" alt="logo">
          </div>
        </div>
      </div>

      <div class="bakdrop" @click="hideMenu"></div>
    </div>

    <div class="menu-switch">
      <div v-if="!sidebar" @click="showMenu">
        <n-icon icon="sandwich">
        </n-icon>
      </div>
      <div v-else @click="hideMenu">
        <n-icon icon="close" />
      </div>
    </div>
    <div v-show="!sidebar" class="logo">
      <img :src="logo" alt="logo">
    </div>

    <div v-show="!sidebar" class="search">
      <n-link :to="{name: 'search'}">
        <n-icon icon="search" />
      </n-link>
    </div>
  </div>
</template>

<script>
import logo from 'assets/logo.png'

export default {
  name: 'TopBar',
  data() {
    return {
      logo,
      sidebar: false,
      userMenu: [
        { icon: 'mUser', title: 'Профиль', link: 'profile.index', },
        { icon: 'mStore', title: 'Магазины', link: 'stores.list', },
        { icon: 'mFlowers', title: 'Мои товары', link: 'my.products', },
        { icon: 'mSales', title: 'Мои продажи', link: 'orders.sales', count: true, },
        { icon: 'mOrders', title: 'Мои заказы', link: 'orders.list', },
        { icon: 'mPartners', title: 'Партнерам', link: 'partners', },
        { icon: 'mSupport', title: 'Помощь', link: 'support', },
      ],
      shopMenu: [
        { icon: 'icon-my-shop', title: 'Мой магазин', link: 'profile.index', },
        { icon: 'mStore', title: 'Магазины', link: 'stores.list', },
        { icon: 'green-heart', title: 'Избранное', link: 'favorites', },
        { icon: 'mSupport', title: 'Помощь', link: 'support', },
      ],
    }
  },
  computed: {
    isStore() {
      return $app.auth.user().hasStore
    },
    salesCount() {
      return $app.store.getter('app.salesCount')
    },
  },
  methods: {
    showMenu() {
      this.sidebar = true
    },
    hideMenu() {
      this.sidebar = !this.sidebar
    },
  },
}
</script>

<style scoped lang="scss">
.top-bar {
  background: #FFFFFF;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.6);
  min-height: 55px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: calc(env(safe-area-inset-top) + 10px);
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 100;

  .menu-wrap {
    position: fixed;
    bottom: 0;
    top: 55px;
    right: 0;
    left: 0;
    z-index: 99;
    transition: background .1s ease-in-out;
  }

  .menu {
    background: #FFF;
    position: fixed;
    top: 55px;
    left: 0;
    right: 40%;
    bottom: 0;
    z-index: 1090;

    .content {
      padding: 26px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      .n-link{
        display: flex;
        align-items: center;
        color: #000;
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .title {
        font-size: 16px;
      }

      .count {
        font-size: 14px;
        color: #4BB222;
        margin-left: 30px;
      }
    }

    .logo {
      top: auto;
      bottom: 26px;
    }
  }

  .bakdrop{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 60%;
    background: rgba(0, 0, 0, .4);
  }

  .menu-switch {
    & > div {
      display: flex;
      align-items: center;
    }
  }

  .logo {
    width: 132px;
    height: 30px;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .search {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
