import BaseApi from 'nast-api'
import BaseRequestBuilder from 'nast-api/RequestBuilder'
import each from 'lodash/each'

/**
 *
 */
export default class NastApi extends BaseApi {

}

/**
 *
 */
export class RequestBuilder extends BaseRequestBuilder {
  /**
   * @param {Function} callback
   * @return {Promise}
   */
  // then(callback) {
  //   each(this._callbacks.before, (callback) => callback())
  //
  //   let promise
  //   if (this._mock) {
  //     promise = new Promise((resolve) => {
  //       setTimeout(() => {
  //         resolve(this._mock())
  //       }, this._mockTimeout)
  //     })
  //   } else {
  //     promise = this._request(this.url(), this._method, this._data, this._config)
  //   }
  //
  //   each(this._callbacks.then, (cb) => {
  //     promise = promise.then(cb)
  //   })
  //
  //   promise = promise.then(callback)
  //
  //   each(this._callbacks.catch, (cb) => {
  //     promise = promise.catch(cb)
  //   })
  //   each(this._callbacks.finally, (cb) => {
  //     promise = promise.finally(cb)
  //   })
  //
  //   console.log(this.url(), this._data)
  //
  //   return promise
  // }
}
