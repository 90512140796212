export default {
  app: {
    state: {
      salesCount: 0,
      product: {
        title: '',
        cost: '',
        category: null,
        description: '',
        code: undefined,
        count: undefined,
        files: [],
      },
      cartCount: 0,
      likes: [],
      needComment: null,
      _save: [ 'likes', ],
    },
    getters: {
      needComment(state) {
        return state.needComment
      },
      salesCount(state) {
        return state.salesCount
      },
      product(state) {
        return state.product
      },
    },
    mutations: {
      needComment(state, value) {
        state.needComment = value
      },
      salesCount(state, value) {
        state.salesCount = value
      },
      cartCount(state, value) {
        state.cartCount = value
      },
      likes(state, value) {
        state.likes = value
      },
      product(state, value) {
        state.product = value
      },
    },
    actions: {
      checkNeedComment({ commit, dispatch, }) {
        $api.app.orders.checkNeedComment().with('seller').then((response) => {
          if (response.data.content) {
            commit('needComment', response.data.content)
          }
        })
      },
      cartCount({ commit, dispatch, }) {
        $api.app.cart.get().then((response) => {
          const count = $n.reduce(response.data.content, (result, item) => {
            return result + item.items.length
          }, 0)
          commit('cartCount', count)
        })
      },
      toggleLike({ state, commit, }, id) {
        const likes = [ ...state.likes, ]
        const i = state.likes.indexOf(id)
        if (i === -1) {
          likes.push(id)
        } else {
          likes.splice(i, 1)
        }
        commit('likes', likes)
      },
    },
    namespaced: true,
  },
}
