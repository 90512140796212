<template>
  <div class="review">
    <div v-for="item in reviews" :key="item.id" class="item">
      <div class="header">
        <div class="title">{{ item.author.fullName }}</div>
        <div>
          <star-rating :read-only="true" :star-size="13" :rating="item.stars" :increment="0.01"></star-rating>
        </div>
      </div>
      <div v-if="item.targetType === 'product'" class="product">
        <n-link :to="{name: 'products.index', params: {id: item.targetId}, }">{{ item.target.title }}</n-link>
      </div>
      <div class="body">
        {{ item.content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Review',
  props: {
    reviews: { type: Array, required: true, },
  },
}
</script>

<style scoped lang="scss">
.review{
  .item {
    margin-bottom: 26px;
    &:last-of-type {
      margin-bottom: 0;
    }

    .header {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::v-deep .vue-star-rating-rating-text{
        display: none;
      }

      .title {
        font-weight: 500;
        font-size: 14px;
        opacity: .5;
      }
    }

    .product {
      font-size: 13px;
      font-style: italic;
      opacity: .5;
      margin-bottom: 4px;
    }

    .body {
      font-size: 14px;
    }
  }
}
</style>
