import BaseRouter from 'nast-router'

/**
 *
 */
export default class NastRouter extends BaseRouter {
  /**
   * @param {Vue} vue
   * @param {Array} routes
   * @param {Object} store
   * @param {Object} config
   */
  constructor(vue, routes, store, config = {}) {
    super(vue, routes, store, config)

    this._vue = vue
    this._routes = routes
    this._store = store

    this._pages = this.constructor._reduceDeep(this._routes, (result, item) => {
      if (item.name) {
        result[item.name] = {
          title: global.__ ? global.__('pages.'.item.name) : (item.title || item.name),
          name: item.name,
          parent: item.parent || null,
          icon: item.icon || null,
          route: item,
        }
      }
      return result
    }, {})

    this.router()
  }
}
