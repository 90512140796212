<template>
  <div class="back">
    <n-loader :loading="loading" />
    <div class="icon-wrap">
      <div @click="goBack">
        <n-icon icon="back" />
      </div>
    </div>

    <div class="icon-wrap">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Back',
  props: {
    back: { type: Object, default: null, },
    loading: { type: Boolean, default: false, },
  },
  methods: {
    goBack() {
      if (this.back) {
        this.$router.push(this.back)
      } else {
        this.$router.go(-1)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.back {
  background: #FFFFFF;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  min-height: 55px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: calc(env(safe-area-inset-top) + 10px);
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 10;

  .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    &>* {
      &:not(:last-child) {
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
}
</style>
