import mockFaqs from '../mocks/mockFaqs'

/**
 *
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'auth/login', ]).data(data),
    register: (data) => $app.api.post([ 'auth/register', ]).data(data),
    sendCode: (data) => $app.api.post([ 'auth/send/code', ]).data(data),
    loginWithCode: (data) => $app.api.post([ 'auth/login/by-code', ]).data(data),
  }

  profile = {
    edit: (data) => $app.api.patch([ 'auth/profile', ]).data(data),
    password: (data) => $app.api.patch([ 'auth/password', ]).data(data),
    store: {
      edit: (data) => $app.api.patch([ 'auth/store', ]).data(data),
    },
  }

  files = {
    get: (id) => $app.api.get([ 'files*', id, ]),
    create: (data) => {
      const formData = new FormData()
      $n.each(data, (value, name) => formData.append(name, value))
      return $app.api.post([ 'files', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
    },
    delete: (id) => $app.api.delete([ 'files*', id, ]),
  }

  app = {
    faqs: () => $app.api.get([ '', ]).mock(mockFaqs.faqs),
    search: (query) => $app.api.get([ 'search', ]).query({ query, }),

    categories: {
      get: (id = undefined) => $app.api.get([ 'categories', id, ]),
    },
    banners: {
      get: (id = undefined) => $app.api.get([ 'banners*', id, ]),
    },
    users: {
      get: (id = undefined) => $app.api.get([ 'users*', id, ]),
    },
    stores: {
      get: (id = undefined) => $app.api.get([ 'stores*', id, ]),
    },
    products: {
      get: (id = undefined) => $app.api.get([ 'products*', id, ]),
      reviews: (id) => $app.api.get([ 'products*/reviews', id, ]),
      edit: (id, data) => $app.api.patch([ 'products*', id, ]).data(data),
      create: (data) => $app.api.post([ 'products', ]).data(data),
      delete: (id) => $app.api.delete([ 'products*', id, ]),
      call: (id) => $app.api.patch([ 'products*/call', id, ]),
      like: (id) => new Promise((resolve) => {
        setTimeout(() => {
          $app.store.action('app.toggleLike', id*1)
          resolve({ data: { content: [], }, })
        }, 50)
      }),
    },
    reviews: {
      get: (id) => $app.api.get([ 'stores*/reviews', id, ]),
      create: (data) => $app.api.post([ 'reviews', ]).data(data),
    },
    cart: {
      get: () => $app.api.get([ 'my/cart', ]),
      getOne: (id, type) => $app.api.get([ 'my/cart**', type, id, ]),
      check: (id, type) => $app.api.get([ 'cart/check**', type, id, ]),
      add: (productId, count = 1) => $app.api.post([ 'cart', ]).data({ productId, count, }),
      count: (productId, count = 1) => $app.api.patch([ 'cart*', productId, ]).data({ count, }),
      remove: (productId) => $app.api.delete([ 'cart*/', productId, ]),
    },
    orders: {
      get: (id) => $app.api.get([ 'orders*', id, ]),
      products: (id) => $app.api.get([ 'orders*/products', id, ]),
      create: (data) => $app.api.post([ 'orders/from-cart', ]).data(data),
      status: (id, data) => $app.api.post([ 'orders*/status', id, ]).data(data),
      checkNeedComment: () => $app.api.get([ 'orders/need-comment', ]),
      view: (id) => $app.api.post([ 'orders*/view', id, ]),
    },
  }

  my = {
    products: () => $app.api.get([ 'my/products', ]),
    storeProducts: () => $app.api.get([ 'my/store/products', ]),
    orders: () => $app.api.get([ 'my/orders', ]),
    sales: () => $app.api.get([ 'my/sells', ]),
    salesCount: () => $app.api.get([ 'my/sells-count', ]),
    favorites: () => $app.api.get([ 'my/products/favorites', ]),
  }
}
