<template>
  <div class="page-title">
    <div class="container">
      <div class="wrap">
        <div>
          <h2 v-if="title">{{ title }}</h2>
          <h2 v-else>&nbsp;</h2>
          <div class="description"><slot name="description">{{ description }}</slot></div>
        </div>
        <div v-if="button || image" class="link-wrap">
<!--          <n-link v-if="button" class="yellow-btn" :to="link">{{ button }}</n-link>-->
          <img :src="image" alt="image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageTitle',
  props: {
    title: { type: String, default: '', },
    description: { type: String, default: '', },
    button: { type: String, default: '', },
    image: { type: String, default: '', },
    link: { type: Object, default: null, },
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped lang="scss">
.page-title {
  background-color: #ECECEC;
  padding: 30px 0;
  margin-bottom: 20px;
  position: relative;
  transition: min-height .3s ease;

  .wrap{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .description {
    padding-top: 5px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    width: 80%;
  }

  .link-wrap {
    margin-left: 10px;
    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .yellow-btn{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #000;
    background: #FFC801;
    border: 1px solid #FFC801;
    border-radius: 100px;
    min-width: 115px;
    padding: 0 10px;
    height: 40px;
    text-decoration: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
  }
}
</style>
