<template>
  <div class="sale-card">
    <div v-for="item in customers" :key="item.id">
      <div class="card">
        <div class="header">
          <div class="status"
               :class="[{success: item.status ==='новый',
                         danger: item.status ==='отменён',}]"
          >
            {{ item.status }}
          </div>
          <div class="date">Дата покупки: {{ item.dateSales }}</div>
        </div>
        <div class="body">
          <div class="left">
            <div class="order-image">
              <div v-for="image in item.images"
                   :key="image"
                   :class="[{small: item.images.length > 1}]" class="image">
                <div class="image-offset">
                  <img :src="image">
                  <img class="plug" :src="plug">
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="info">
              <div class="title">Заказ № {{ item.number }}</div>
              <div>Покупатель: <span class="dark">{{ item. name }}</span></div>
              <div>Телефон: <span class="dark">{{ item.phone }}</span></div>
              <div>Способ получения: <span class="dark">{{ item.getting }}</span></div>
              <div>Время: <span class="dark">{{ item.time }}</span></div>
            </div>
            <div class="quantity-and-price">
              <div class="quantity">{{ item.count }} шт</div>
              <div class="price">{{ item.price }} ₸</div>
            </div>

            <n-button color="primary">Подтвердить</n-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plug from 'assets/plug.png'

export default {
  name: 'SaleCard',
  props: {
    customers: { type: Array, required: true, },
  },
  data() {
    return {
      plug,
    }
  },
}
</script>

<style scoped lang="scss">
.card {
  margin-bottom: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .status {
      font-size: 12px;

      &.success {
        color: #169401;
      }

      &.danger {
        color: #FF5139;
      }
    }

    .date {
      font-size: 12px;
      color: #404040;
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    background: #FFF;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    padding: 15px;

    .left {
      padding-right: 7px;
      width: 40%;

      .order-image {
        display: flex;
        flex-wrap: wrap;
        height: 115px;
        overflow: hidden;
      }

      .image {
        width: 115px;
        height: 115px;
        text-align: center;

        .image-offset {
          padding: 3px;
          height: 100%;
          width: 100%;
        }

        &.small {
          width: 57px;
          height: 57px;

          .plug {
            display: none;
          }

          &:nth-child(4) {
            .plug {
              display: block;
            }

            img {
              display: none;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .right {
      padding-left: 7px;
      width: 60%;
    }

    .title {
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 12px;
      color: #000;
    }

    .info {
      border-bottom: 1px solid #F2F2F2;
      padding-bottom: 5px;
      margin-bottom: 5px;
      font-size: 12px;
      color: #BABABA;

      & > div {
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .dark {
        color: #404040;
        margin-left: 3px;
      }
    }

    .quantity-and-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #404040;
      font-size: 14px;

      .price {
        font-weight: bold;
      }
    }
  }

  .n-button{
    font-size: 9px;
    color: #FFF;
    width: 100%;
    border-radius: 10px;
    border: solid 1px #2A82D3;
    margin-top: 10px;
  }
}
</style>
