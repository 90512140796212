<template>
  <div class="c-products-list">
    <n-loader :loading="loading" />
    <c-product-card v-for="product in data" :key="product.id" :product="product" :list="list" :wide="wide" />
  </div>
</template>

<script>
export default {
  name: 'CProductsList',
  props: {
    data: { type: Array, default: () => [], },
    wide: { type: Boolean, default: false, },
    list: { type: Boolean, default: false, },
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.c-products-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  min-height: 170px;

  .c-product-card {
    padding: 5px;
  }
}
</style>
