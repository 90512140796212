<template>
  <div v-infinite-scroll="loadMore" class="c-infinity-scroll" :infinite-scroll-disabled="!active"
       :infinite-scroll-distance="100">
    <slot />
  </div>
</template>

<script>
import InfiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'CInfinityScroll',
  directives: { InfiniteScroll, },
  props: {
    more: { type: Function, required: true, }, // (page) => {}
    active: { type: Boolean, default: true, },
  },
  data() {
    return {
      page: 0,
    }
  },
  methods: {
    loadMore() {
      if (this.active) {
        this.page++
        this.more(this.page)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-infinity-scroll {

}
</style>
