<template>
  <div class="app-bar">
    <template v-for="page in menu">
      <div v-if="page.name !== 'button'" :key="page.name" :class="['item', {active: page.name === active}]">
        <router-link :to="{name: page.link}">
          <div class="img">
            <div v-if="page.cart && $app.store.state('app.cartCount') > 0 && $app.auth.loggedIn()" class="count">
              {{ $app.store.state('app.cartCount') }}
            </div>
            <div v-if="page.sales && $app.store.state('app.salesCount') > 0 && $app.auth.loggedIn()" class="count">
              {{ $app.store.state('app.salesCount') }}
            </div>
            <div v-if="page.isFavorite && $app.store.state('app.likes').length" class="favorite-alert"></div>
            <n-icon :icon="page.icon" />
          </div>
          <div class="title">{{ page.title }}</div>
        </router-link>
      </div>

      <div v-else :key="page.name" class="add-button">
        <router-link :to="{name: page.link}"></router-link>
        <n-icon icon="plus" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppBAr',
  data() {
    return {
      active: 'home',
    }
  },
  computed: {
    menu() {
      const menu = [
        { icon: 'main', title: 'Главная', link: 'index', },
        { icon: 'catalog', title: 'Каталог', link: 'categories.list', },
        { name: 'button', link: 'products.create', },
      ]
      if ($app.auth.user().hasStore) {
        menu.push({ icon: 'shop-goods', title: 'Мои товары', link: 'my.products', })
        menu.push({ icon: 'sales-shop', title: 'Мои продажи', link: 'orders.sales', sales: true, })
      } else {
        menu.push({ icon: 'menu-heart', title: 'Избранное', link: 'favorites', isFavorite: true, })
        menu.push({ icon: 'cart', title: 'Корзина', link: 'cart', cart: true, })
      }
      return menu
    },
  },
}
</script>

<style scoped lang="scss">
.app-bar {
  background: #FFFFFF;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  min-height: 55px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 98;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px env(safe-area-inset-bottom);

  .count {
    width: 13px;
    height: 13px;
    background-color: var(--secondary);
    border-radius: 50%;
    position: absolute;
    top: -3px;
    left: calc(50% + 10px);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
  }

  .favorite-alert{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--secondary);
    position: absolute;
    left: calc(50% + 12px);
    top: 3px;
  }

  .add-button {
    background: #FFFFFF;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    top: -22px;
    border: none;

    a{
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .lni-plus{
      width: 20px;
    }
  }

  .item {
    width: 100px;
    text-align: center;
    position: relative;

    a{
      text-decoration: none;
    }

    .router-link-exact-active{
      text-decoration: none;
      &::v-deep svg{
        path{
          fill: #000;
        }
      }

      .title {
        color: #000;
      }
    }
  }

  .title {
    font-size: 9px;
    color: #BABABA;
    transition: color .2s ease-in-out;
  }

  .img {
    display: flex;
    justify-content: center;
    height: 28px;
    margin-bottom: 4px;

    &::v-deep svg{
      width: 20px;
      path{
        fill: #BABABA;
      }
    }
  }
}
</style>
