<template>
  <div :class="[ 'c-product-card', [{wide},{list}], ]">
    <n-loader :loading="loading" />
    <div class="content">
      <div class="add-likes">
        <n-icon v-if="!$app.store.state('app.likes').includes(product.id)" icon="heart" @click="toggleLike" />
        <n-icon v-else icon="heart-filled" @click="toggleLike" />
      </div>
      <n-link :to="{name: 'products.index', params: {id: product.id}}" />
      <div class="img">
        <img :src="product.avatar" alt="">
      </div>
      <div class="description">
        <h5>{{ product.title }}</h5>

        <div class="price">{{ product.cost }} ₸</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CProductCard',
  props: {
    product: { type: Object, required: true, },
    wide: { type: Boolean, default: false, },
    list: { type: Boolean, default: false, },
  },
  computed: {
    loading() {
      if (this.$route.name === 'favorites') {
        return false
      }
      return this.$var('load')
    },
  },
  methods: {
    toggleLike() {
      this.$var('load', true)
      $api.app.products.like(this.product.id).then((response) => {
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.c-product-card {
  width: 50%;
  transition: width .3s ease;
  position: relative;

  &.list {
    width: 100%;
    .content{
      display: flex;
      height: 120px;

      .img {
        height: 100%;
        width: 40%;
        border-radius: 10px;
      }

      .add-likes {
        top: 5px;
      }

      .description {
        padding-top: 35px;
        flex: 1;
      }
    }
  }

  &.wide {
    width: 100%;
  }

  .content {
    height: 174px;
    background: #FFFFFF;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 2;
    }
  }

  .add-likes {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #F59477;
    font-size: 20px;
    z-index: 5;
  }

  .img {
    height: 106px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    transition: all .3s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .description {
    padding: 10px;
    position: relative;
  }

  .price {
    font-weight: bold;
    font-size: 12px;
    color: #404040;
  }
}
</style>
