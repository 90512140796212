import AppBar from './AppBar'
import Back from './Back'
import Search from './Search'
import TopBar from './TopBar'
import PageTitle from './page-title/Index'
import NImageUpload from './img-upload/Index'
import CAuthModal from './auth-modal/Index'
import CProductCard from './product-card/Index'
import SaleCard from './sale-card/Index'
import CBanners from './banners/Index'
import Sort from './sort/Sort'
import Review from './review/Index'
import CProduct from './product/Index'
import CProductsList from './products-list/Index'
import CProductsListTop from './products-list-top/Index'
import CInfinityScroll from './infinity-scroll/Index'

// внешние библиотеки
import StarRating from 'vue-star-rating'

export default {
  AppBar,
  TopBar,
  Back,
  Search,
  PageTitle,
  NImageUpload,
  CAuthModal,
  SaleCard,
  StarRating,
  CBanners,
  Sort,
  Review,

  CInfinityScroll,

  CProduct,
  CProductCard,
  CProductsList,
  CProductsListTop,
}

