import star from 'assets/icons/star.svg'
import starFilled from 'assets/icons/star-filled.svg'
import tile from 'assets/icons/tile.svg'
import tileGreen from 'assets/icons/tile-green.svg'
import list from 'assets/icons/list.svg'
import listGreen from 'assets/icons/list-green.svg'
import main from 'assets/icons/app-bar/mainLi.svg'
import catalog from 'assets/icons/app-bar/catalogLI.svg'
import heart from 'assets/icons/heart.svg'
import cart from 'assets/icons/cartLi.svg'
import plus from 'assets/icons/plus.svg'
import sandwich from 'assets/icons/sandwich.svg'
import balloon from 'assets/icons/balloon.svg'
import back from 'assets/icons/back.svg'
import share from 'assets/icons/share.svg'
import trashBasket from 'assets/icons/trash-basket.svg'
import close from 'assets/icons/close.svg'
import search from 'assets/icons/search.svg'
import allCategories from 'assets/icons/all-categories.svg'
import mFlowers from 'assets/icons/menu-icon/mFlowers.svg'
import mUser from 'assets/icons/menu-icon/mUser.svg'
import mStore from 'assets/icons/menu-icon/mStore.svg'
import mOrders from 'assets/icons/menu-icon/mOrders.svg'
import mPartners from 'assets/icons/menu-icon/mPartners.svg'
import mSupport from 'assets/icons/menu-icon/mSupport.svg'
import mSales from 'assets/icons/menu-icon/mSales.svg'
import redHeart from 'assets/icons/menu-icon/red-heart.svg'
import logOut from 'assets/icons/menu-icon/log-out.svg'
import angleRight from 'assets/icons/menu-icon/angle-right.svg'
import angleLeft from 'assets/icons/menu-icon/angle-left.svg'
import angleDown from 'assets/icons/menu-icon/angle-down.svg'
import iconMyShop from 'assets/icons/menu-icon/icon-my-shop.svg'
import greenHeart from 'assets/icons/menu-icon/green-heart.svg'
import shopGoods from 'assets/icons/menu-icon/shop-goods.svg'
import salesShop from 'assets/icons/menu-icon/sales-shop.svg'
import newHeart from 'assets/icons/menu-icon/new-heart.svg'
import check from 'assets/icons/checkmark.svg'

// icon app-bar
import home from 'assets/icons/app-bar/home.svg'

export default {
    // icon app-bar
    home,
    check,
    star,
    'star-filled': starFilled,
    'menu-heart': newHeart,
    trashBasket,
    plus,
    share,
    sandwich,
    balloon,
    tile,
    'tile-green': tileGreen,
    list,
    'list-green': listGreen,
    main,
    heart,
    catalog,
    cart,
    back,
    close,
    search,
    'all-categories': allCategories,
    mFlowers,
    mUser,
    mStore,
    mOrders,
    mPartners,
    mSupport,
    mSales,
    redHeart,
    logOut,
    'angle-right': angleRight,
    'angle-down': angleDown,
    'icon-my-shop': iconMyShop,
    'green-heart': greenHeart,
    'shop-goods': shopGoods,
    'sales-shop': salesShop,
    'angle-left': angleLeft,
}
