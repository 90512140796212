export default [
  { path: '', component: () => import('layouts/App'), children: [

    { path: 'auth/', component: () => import('layouts/auth/Index'), children: [
      { path: 'login', name: 'auth.login', component: () => import('pages/auth/login/Index'), }, // alpha
      { path: 'forgot/step/1', name: 'auth.forgot', component: () => import('pages/auth/forgot/Step1'), }, // alpha
      { path: 'forgot/step/2', name: 'auth.forgot2', component: () => import('pages/auth/forgot/Step2'), }, // alpha
      { path: 'forgot/step/3', name: 'auth.forgot3', component: () => import('pages/auth/forgot/Step3'), }, // alpha
      { path: 'register/step/1', name: 'auth.register1', component: () => import('pages/auth/register/Step1'), }, // alpha
      { path: 'register/step/2', name: 'auth.register2', component: () => import('pages/auth/register/Step2'), }, // alpha
      { path: 'register/step/3', name: 'auth.register3', component: () => import('pages/auth/register/Step3'), }, // alpha
    ], },

    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: '', name: 'index', component: () => import('pages/index/Index'), main: true, }, // alpha
      { path: 'search', name: 'search', component: ()=> import('pages/search/Index'), }, // todo
      { path: 'partners', name: 'partners', component: () => import('pages/partners/Index'), }, // alpha
      { path: 'support', name: 'support', component: () => import('pages/support/Index'), }, // alpha

      { path: 'categories', name: 'categories.list', component: () => import('pages/categories/list/Index'), main: true, }, // alpha
      { path: 'categories/:id/sub', name: 'categories.sub', component: () => import('pages/categories/sub/Index'), }, // alpha

      { path: 'products', name: 'products.list', component: () => import('pages/products/list/Index'), }, // alpha
      { path: 'products/create', name: 'products.create', component: () => import('pages/products/create/Index'), auth: true, }, // alpha
      { path: 'products/:id/edit', name: 'products.edit', component: () => import('pages/products/edit/Index'), auth: true, }, // todo
      { path: 'products/:id', name: 'products.index', component: () => import('pages/products/index/Index'), }, // alpha
      { path: 'products/:id/photos/:photoId', name: 'products.photos', component: () => import('pages/products/photos/Index'), }, // alpha

      { path: 'stores', name: 'stores.list', component: () => import('pages/stores/list/Index'), }, // alpha
      { path: 'stores/:id', name: 'stores.index', component: () => import('pages/stores/index/Index'), }, // alpha

      { path: 'reviews/create', name: 'reviews.create', component: () => import('pages/reviews/create/Index'), auth: true, }, // alpha
      { path: 'products/:id/reviews', name: 'products.reviews', component: () => import('pages/reviews/products/Index'), }, // alpha
      { path: 'stores/:id/reviews', name: 'stores.reviews', component: () => import('pages/reviews/stores/Index'), }, // alpha


      // Внутренние страницы
      { path: 'favorites', name: 'favorites', component: () => import('pages/favorites/Index'), main: true, auth: true, }, // alpha

      { path: 'profile', name: 'profile.index', component: () => import('pages/profile/index/Index'), auth: true, }, // alpha
      { path: 'profile/edit', name: 'profile.edit', component: () => import('pages/profile/edit/Index'), auth: true, }, // alpha

      { path: 'cart', name: 'cart', component: () => import('pages/cart/Index'), auth: true, main: true, }, // alpha
      { path: 'cart/:type/:id/', name: 'orders.create', component: () => import('pages/orders/create/Index'), auth: true, }, // alpha
      { path: 'orders', name: 'orders.list', component: () => import('pages/orders/list/Index'), auth: true, }, // alpha
      { path: 'orders/:id', name: 'orders.index', component: () => import('pages/orders/index/Index'), auth: true, }, // alpha
      { path: 'sales', name: 'orders.sales', component: () => import('pages/orders/sales/Index'), auth: true, }, // alpha

      { path: 'my/products', name: 'my.products', component: () => import('pages/products/my/Index'), auth: true, }, // alpha


      // Сообщения
      { path: 'success/order/:id', name: 'messages.success.order', component: () => import('pages/messages/success/Order'), auth: true, main: true, },
    ], },
  ], },
]
