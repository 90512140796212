<template>
  <div class="n-image-upload" @click="selectFile">
    <div id="addImage" class="event-image">
      <div id="preview">
        <img v-if="url" :src="url" alt="" />
        <div v-if="!url" class="url">
          <span class="label">Добавить фото</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Camera, CameraResultType, CameraSource, } from '@capacitor/camera'

export default {
  name: 'NImageUpload',
  props: {
    file: { type: [ File, Array, FileList, ], default: () => [], },
    multi: { type: Boolean, default: false, },
    from: { type: String, default: 'both', }, // both, camera, gallery
  },
  data() {
    return {
      url: null,
    }
  },
  methods: {
    selectFile() {
      const mapper = {
        both: CameraSource.Prompt,
        camera: CameraSource.Camera,
        gallery: CameraSource.Photos,
      }

      Camera.getPhoto({
        source: mapper[this.from],
        quality: 90,
        resultType: CameraResultType.DataUrl,
      }).then((response) => {
        const file = this.dataURLtoFile(response.dataUrl, 'image' + this.file.length)
        if (this.multi) {
          this.$emit('update:file', $n.concat(this.file, [ file, ]))
        } else {
          this.url = URL.createObjectURL(file)
          this.$emit('update:file', file)
        }
      })
    },
    dataURLtoFile(dataUrl, filename) {
      const parts = dataUrl.split(',')
      const mime = parts[0].match(/:(.*?);/)[1]
      const fileContent = atob(parts[1])
      let n = fileContent.length
      const bitArray = new Uint8Array(n)
      while (n--) {
        bitArray[n] = fileContent.charCodeAt(n)
      }

      return new File([ bitArray, ], filename, { type: mime, })
    },
  },
}
</script>

<style lang="scss" scoped>
.n-image-upload {
  margin-bottom: 10px;
  .event {
    &-image {
      background-color: #ECECEC;
      height: 138px;
      position: relative;
      border-radius: 10px;
      overflow: hidden;

      input {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        width: 100%;
      }
    }
  }

  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    &::after {
      content: '';
      position: absolute;
      background-image: url("./../../assets/icons/add-photo.png");
      width: 71px;
      height: 62px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  #preview img {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    z-index: 1;
  }

  .url {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .label {
      font-weight: 500;
      font-size: 14px;
      color: #5C5C5C;
      position: relative;
      z-index: 1;
    }
  }
}
</style>
