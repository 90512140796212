export default {
  faqs: () => {
    return {
      data: {
        content: [
          {
            question: 'Как долго стоят цветы?',
            answer: 'Наша мастерская находится рядом с ' +
              'оптовыми базами и каждый день мы выбираем у поставщиков самые свежие цветы.' +
              ' Однако стойкость очень сильно зависит от сорта. Как правило, самые стойкие (от 5 до 14 дней) - гвоздики,' +
              ' классические розы, каллы, гиацинты. Самые нежные цветы (от 3 до 5 дней) - пионовидные розы, ' +
              'пионы. К каждому букету мы прилагаем памятку по уходу и средство для продления жизни срезанных цветов.',
            open: true,
          },
          {
            question: 'Цветы точно свежие?',
            answer: 'Самые свежие из всех возможных. ' +
              'Наша мастерская находится в 30 секундах ходьбы от цветочных баз и мы ' +
              'закупаем свежие цветы каждый день, как только они приходят на оптовые базы.',
            open: false,
          },
          {
            question: 'Букеты в жизни такие же, как на фото?',
            answer: 'Точно такие же. Если вы переживаете, мы можем прислать вам фото вашего букета ' +
              'перед доставкой в Whatsapp или на почту – пожалуйста, укажите это в ' +
              'форме для заказа или попросите менеджера в переписке.',
            open: false,
          },
          {
            question: 'Не подведете ли вы меня с доставкой?',
            answer: 'Мы всегда очень ответственно подходим к процессу доставки, ' +
              'потому что знаем, как важна в нашем деле скорость. ' +
              'Если ваш заказ подтвержден, можете не волноваться, ' +
              'ваш букет будет доставлен ровно в указанное время.  ' +
              'Если что-то пойдет не так - мы вернем вам деньги.',
            open: false,
          },
          {
            question: 'Бывают ли скидки на букеты?',
            answer: 'Да, мы часто анонсируем специальные предложения и розыгрыши в социальных сетях, ' +
              'вы можете подписаться на нас в instagram. ' +
              'Еженедельно на сайте появляются букеты недели по ' +
              'специальной цене. Помимо этого, для наших постоянных клиентов ' +
              'действует бессрочная скидка 10% на все букеты.',
            open: false,
          },
        ],
      },
    }
  },
}
