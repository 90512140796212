
const deliveryMethods = {
  delivery: { name: 'delivery', title: 'Доставка', },
  pickup: { name: 'pickup', title: 'Самовывоз', },
}
const paymentMethods = {
  card: { name: 'card', title: 'Перевод на Kaspi', },
  cash: { name: 'cash', title: 'Наличными', },
}
const productStatuses = {
  active: { name: 'Активный', title: 'Активный', color: 'success', },
  sold: { name: 'sold', title: 'Продан', color: 'default', },
  canceled: { name: 'canceled', title: 'В архиве', color: 'danger', },
}


export default {
  numberFormat(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
    return ''
  },
  phoneFormat(number) {
    const cleaned = ('' + number).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d+)$/)
    if (match) {
      return '+7 (' + match[2] + ') ' + match[3] + '-' + match[4] + '-' + match[5]
    }

    return number
  },

  paymentMethod(method = undefined) {
    return method ? paymentMethods[method] : paymentMethods
  },
  deliveryMethod(method = undefined) {
    return method ? deliveryMethods[method] : deliveryMethods
  },
  productStatus(status = undefined) {
    return status ? productStatuses[status] : productStatuses
  },
  orderStatus(status = undefined) {
    const orderStatuses = {
      formed: { name: 'formed', title: $app.auth.user().hasStore ? 'Новый' : 'В обработке', color: 'default', },
      approved: { name: 'approved', title: 'Подтвержден', color: 'default', },
      sold: { name: 'sold', title: 'Исполнен', color: 'success', },
      canceled: { name: 'canceled', title: 'Отменен', color: 'danger', },
    }

    return status ? orderStatuses[status] : orderStatuses
  },
  addressInfo(data) {
    return data.address +
      ', квартира ' + (data.flat || 'не указана') +
      (data.door ? ', подъезд ' + data.door : '') +
      (data.comment ? ', ' + data.comment : '')
  },
}
