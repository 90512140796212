<template>
  <div class="search">
    <n-icon icon="lni lni-search-alt" />
    Поиск по категории
  </div>
</template>

<script>
export default {
  name: 'Search',
}
</script>

<style scoped lang="scss">
.search {
  background: #FFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 12px;
  color: #ACACAC;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 11px;

  i{
    margin-right: 12px;
    font-size: 18px;
  }
}
</style>
