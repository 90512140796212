<template>
  <div class="c-top-products">
    <n-slider :data="data" :options="options" :loading="loading" scrollbar>
      <template #item="{item}">
        <c-product-card :product="item" wide />
      </template>
    </n-slider>
  </div>
</template>

<script>
export default {
  name: 'CTopProducts',
  props: {
    data: { type: Array, default: () => [], },
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
      options: {
        spaceBetween: 10,
        slidesPerView: 'auto',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.c-top-products {
  &::v-deep {
    .n-slider {
      min-height: 195px;
      margin-bottom: 20px;
    }
    .swiper-container {
      padding-left: 20px;
      padding-right: 20px;
    }

    .swiper-slide {
      width: 40%;
      .product {
        padding: 0;
      }
    }
  }
}
</style>
