<template>
  <div class="c-auth-modal modal">
    <n-card :loading="loading">
      <div v-if="back" class="back">
        <div @click="goBack"> <n-icon icon="angle-left"></n-icon> </div>
      </div>
      <div class="title">
        <h2>{{ title }}</h2>
        <div class="sub"><slot name="sub">{{ sub }}</slot></div>
      </div>
      <slot></slot>
    </n-card>
  </div>
</template>

<script>
export default {
  name: 'CAuthModal',
  props: {
    title: { type: String, default: '', },
    sub: { type: String, default: '', },
    back: { type: [ Object, Boolean, ], default: null, },
    loading: { type: Boolean, default: false, },
  },
  created() {
  },
  methods: {
    goBack() {
      if (this.back === true) {
        this.$router.go(-1)
      } else {
        this.$router.push(this.back)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-auth-modal {
  padding: 40px 0;
}
</style>
