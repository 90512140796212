<template>
  <div class="c-banners">
    <n-loader :loading="loading" />
    <n-slider v-if="data.length" :data="data" :options="options">
      <template #item="{item}">
        <n-link :to="link(item)">
          <img :src="item.image" alt="">
        </n-link>
      </template>
    </n-slider>
  </div>
</template>

<script>
export default {
  name: 'CBanners',
  props: {
    data: { type: Array, default: () => [], },
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
      options: {
        spaceBetween: 20,
        slidesPerView: 'auto',
        loop: true,
        centeredSlides: true,
      },
    }
  },
  methods: {
    link(item) {
      if (item.targetType === 'product') {
        return { name: 'products.index', params: { id: item.targetId, }, }
      } else if (item.targetType === 'store') {
        return { name: 'stores.index', params: { id: item.targetId, }, }
      }
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.c-banners {
  min-height: 140px;
  position: relative;

  &::v-deep {
    .n-slider {
      min-height: 180px;
      padding: 20px 0 20px;
    }
    .swiper-slide {
      width: 80%;
      height: 140px;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
